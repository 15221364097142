<template>
    <div id="page-crowded-detail">
        <div class="back-detail">
            <vs-button class="mb-4 mr-4" type="border" color="primary" @click="strangerList">Xem CAM</vs-button>
            <vs-button class="mb-4" type="border" color="warning" @click="strangerList">Quay lại</vs-button>
        </div>
        <div class="vx-card p-6">
            <div style="display: flex; flex-direction: row">
                <div class="vx-col" style="flex: 1">
                    <img id="product-detail" style="width: 100%" v-bind:src="imageUrl"/>
                </div>
                <div class="w-info" style="flex: 2; display: flex; flex-direction: column">
                    <div class="inner" style="display: flex">
                        <div class="vx-col md:w-1/3 w-full info" style="flex: 2">
                            <div class="name">
                                <label>Tên cửa hàng:</label><span> {{ employeeName }}</span>
                            </div>
                            <div class="camera">
                                <label>Tên camera:</label><span> {{ cameraName }}</span>
                            </div>
                            <div class="camera">
                                <label>Ngày tụ tập:</label><span> {{ displayCreatedAt }}</span>
                            </div>
                        </div>
                        <div class="vx-col md:w-1/3 w-full action" style="flex: 1">
                            <vs-button class="mb-4 float-right" type="border" color="position" @click="confirm">Gửi xác
                                nhận
                            </vs-button>
                            <vs-button class="mb-4 float-right" type="border" color="warning" @click="strangerList">In
                                phiếu phạt
                            </vs-button>
                        </div>
                    </div>
                    <div class="block-history info mt-5" style="width: 100%; flex-grow: 1; padding-left: 20px">
                        <div class="label mb-3">
                            Lịch sử xử lý
                        </div>
                        <vs-table :max-items="5" pagination :data="historyList" class="table-dark-report">
                            <template slot="thead">
                                <vs-th class="text-center">Hành động</vs-th>
                                <vs-th class="text-center">Người thực hiện</vs-th>
                                <vs-th class="text-center">Thời gian</vs-th>
                            </template>
                            <template slot-scope="{data}">
                                <tbody>
                                <vs-tr class="hoverFlat" :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td :data="data[indextr].action">
                                        <span>{{ data[indextr].action }}</span>
                                    </vs-td>
                                    <vs-td :data="data[indextr].user">
                                        <span>{{ data[indextr].user }}</span>
                                    </vs-td>
                                    <vs-td :data="data[indextr].date">
                                        <span>{{ data[indextr].date }}</span>
                                    </vs-td>
                                </vs-tr>
                                </tbody>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
    components: {},
    data() {
        return {
            historyList: [
                {
                    action: "Gửi yêu cầu xác nhận",
                    user: "Việt",
                    date: "25/09/2020 17:04:32"
                },
                {
                    action: "Đã xác nhận",
                    user: "Lương",
                    date: "25/08/2020 17:04:32"
                },
                {
                    action: "Đã gửi phiếu phạt",
                    user: "Trường",
                    date: "25/06/2020 17:04:32"
                }
            ],
            imageUrl: "",
            employeeName: "",
            cameraName: "",
            displayCreatedAt: ""
        }
    },
    created() {
        this.imageUrl = this.$route.query.imageUrl;
        this.employeeName = this.$route.query.employeName;
        this.cameraName = this.$route.query.cameraName;
        this.displayCreatedAt = this.$route.query.date;
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteCameraStatus'(val) {
            if (val) {
                this.$store.commit('DELETE_CAMERA_STATUS', false);
            }
        },
    },
    computed: {},
    methods: {
        strangerList() {
            this.$router.push(`/user/manage-crowded`).catch(() => {
            })
        },
        confirm() {
            this.$vs.dialog({
                type: 'confirm',
                title: `Xác nhận`,
                text: `Bạn có muốn gửi xác nhận`,
                accept: '',
                acceptText: "Đồng ý",
                cancelText: "Huỷ"
            })
        }
    },
    mounted() {

    }
}
</script>

<style>
.block-history .vs-table--thead tr {
    background: rgba(0, 0, 0, .05);
}

.block-history .vs-con-table .vs-con-tbody {
    border: none;
}

.block-history .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    border: 1px solid #dedede;
    border-top: none;
}

.block-history .title > div {
    padding-left: 10px;
}

#page-crowded-detail .info > div {
    margin-bottom: 5px;
}

#page-crowded-detail .info {
    padding-left: 20px;
}

#page-crowded-detail .block-history .label, #page-crowded-detail .info label {
    font-weight: bold;
    font-size: 14px;
}

.main-vertical.navbar-sticky .router-content {
    position: relative;
}

.back-detail {
    position: absolute;
    right: 0;
    top: -5px;
}
</style>
